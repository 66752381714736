.backg {
    background-color: rgb(255, 231, 199);
    font-style: oblique;
    font-size: 2rem;
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 10px;
}

.pdf {
    background-color: rgb(0, 0, 0);
    text-align: center;
    padding: 2rem;
}

.pdfwindow {
    width: 400px;
    height: 600px;
}

@media (max-width: 768px) {
    .pdfwindow {
        max-height: 520px;
        min-width: 350px;
        width: auto;
    }
    
}