table {
  width: 1000px;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid black;
  width: fit-content;

}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: gray;
}

td {
  background-color: whitesmoke;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}


.updatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background: lightblue;
  gap: 10px;
}

