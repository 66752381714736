/* imgs.css and past.css */
.column {
  font-size: small;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  align-items: center;
  padding: 1rem;
}

.grid ul {
  list-style: none;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0;
  margin: 2rem;
}

.grid {
  text-decoration: none;
  padding-bottom: 7rem;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
}

.strip {
  background-color: green;
  align-items: center;
  padding-left: 2rem;
  height: 100%;
}

.column h1 {
  font-size: 1.5rem;
  font-style: italic;
}

.headline-past {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 3rem;
  font-style: oblique;
  color: black;
}

.search-bar {
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  max-width: 600px;
  height: 50px;
  border-radius: 15px;
}

.bigpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 50px;
  padding-top: 50px;
  padding: 20px;
}

.search-stuff {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.option-select {
  background-color: lightblue;
  min-width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1rem;
  margin-left: 1rem;
}

.wix-madefor-display {
  font-family: "Wix Madefor Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.viewcounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.ViewBoxCounter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Mobile styles */
@media (max-width: 768px) {
  .grid ul {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 1rem;
  }

  .column {
    font-size: small;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    color: black;
    align-items: center;
    padding: 1rem;
  }

  .search-stuff {
    flex-direction: column;
    gap: 10px;
  }

  .option-select {
    min-width: 100%;
    margin-left: 0;
  }

  .bigpage {
    gap: 30px;
  }

  .viewcounter {
    flex-direction: column;
  }
}
