/* General styles */
* {
    box-sizing: border-box;
}

.pink {
    color: pink;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
}

.nerko-one-regular {
    font-family: "Nerko One", cursive;
    font-weight: 800;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    font-size: 4rem;
}

.nerko-one-small {
    font-family: "Nerko One", regular;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.nerko-one-small-reg {
    font-family: "Nerko One", regular;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}


.nav {
    background-color: rgb(140, 107, 109);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 4rem;
    padding-left: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
    text-align: center;
}

.nav a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 70%;
}

.nav a.active {
    background-color: rgb(248, 199, 200);
    color: black; /* Change text color */
}

.nav a:hover {
    background-color: rgb(248, 199, 200);
    color: black;
}

.footer {
    background-color: #222;
    color: white;
    display: flex;
    justify-content: space-between; /* Aligns items to opposite ends of the container */
    align-items: center; /* Centers items vertically */
    padding: 1rem 2rem; /* Padding for top/bottom, left/right */
    font-size: 1rem; /* Adjust font size as needed */
}

.footer a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer a:hover {
    color: #ccc;
}

.footer .left-content {
    display: flex;
    flex-direction: column;
}

.footer .left-content h1 {
    font-size: 1.5rem;
    margin: 0;
}

.footer .left-content p {
    margin: 0.5rem 0 0 0;
}

.footer .right-content ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.footer .right-content ul li {
    display: inline-block; /* Ensures list items are displayed in a row */
}

.footer .right-content ul li:hover {
    transform: scale(1.1); /* Ensures list items are displayed in a row */
}

/* Mobile styles */
@media (max-width: 768px) {
    .nav {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 10px;
    }

    .nav ul.logo-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav .dropdown-toggle {
        display: block;
        background: none;
        border: none;
        font-size: 2rem;
        cursor: pointer;
    }

    .nav ul.nav-items {
        display: none;
        flex-direction: column;
        width: 100%;
    }

    .nav ul.nav-items.dropdown-open {
        display: flex;
    }

    .nav ul.nav-items li {
        width: 100%;
        text-align: center;
    }

    .nav ul.nav-items li a:hover {
        transform: scale(1.1);
    }

    .footer {
        flex-direction: column;
        text-align: center;
    }

    .footer .left-content, 
    .footer .right-content {
        margin: 1rem 0;
    }

    .footer .left-content h1 {
        font-size: 1.2rem;
    }
}
