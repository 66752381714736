.TeamsBackground {
    background-color: #f7bbcc;
    padding: 20px;
  }
  
  .back3 {
    background-color: #7d4c41;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .qn {
    font-family: 'madimi-one-smol';
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bubblyCloudButton a {
    text-decoration: none;
  }
  
  .bubblyCloudButton {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .bubblyCloudButton a div {
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .bubblyCloudButton a div:hover {
    background-color: #cccccc;
  }
  
  .imagesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    grid-template-areas: 
      "art event layout"
      "submission submission marketing";
  }
  
  .teamCard {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 2px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teamCard img {
    border-radius: 20px;
    margin: 2px;
  }
  
  .teamButton {
    margin-top: 10px;
    font-size: 32px;
    padding: 10px 30px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-family: 'wix-madefor-display';
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
    justify-content: center;
  }
  
  .artTeam {
    background-color: #f9c74f;
  }
  
  .eventTeam {
    background-color: #90be6d;
  }
  
  .layoutTeam {
    background-color: #577590;
  }
  
  .submissionTeam {
    background-color: #4cc9f0;
  }
  
  .marketingTeam {
    background-color: #f9844a;
  }

/* Media Query for screens less than 1500px */
@media (max-width: 1500px) {
  .imagesGrid {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-template-areas: 
      "art event"
      "layout submission"
      "marketing marketing";
  }
}

/* Media Query for screens less than 1000px */
@media (max-width: 1000px) {
  .imagesGrid {
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-areas: 
      "art"
      "event"
      "layout"
      "submission"
      "marketing";
  }
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .imagesGrid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive columns */
    grid-template-areas: 
      "art"
      "event"
      "layout"
      "submission"
      "marketing";
  }

  .teamCard img {
    height: 350px;
    width: 250px;
  }

  .teamCard {
    width: fit-content;
    margin: 0 auto;
  }
}
