.bkgground {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qa-form {
    margin: 1rem;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-btn {
  min-width: 700px;
  min-height: 60px;
  border-radius: 30px;
  background-color: white;
  border: none;
  margin-left: 0;
  padding-left: 15px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.madimi-one-lilbig {
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    padding-top: 2rem;
  }

.back2 {
    background-color: rgba(234, 122, 212, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    border-radius: 50px;
    padding: 1rem;
    max-width: 800px;
}

.back2 quest {
    font-size: 1.5rem; 
    color: white; 
    margin-bottom: 0.5rem; 
    padding: 10px;
    font-weight: bold;
  }
  
  .qa-form .ans {
    font-size: 1.2rem; 
    color: white; 
    margin-bottom: 1rem; 
    padding: 2.5rem; 
  }
  
  .lists-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .ansli_left,
  .ansli_right {
    flex: 1;
    padding: 0; 
  }
  
  .ansli_left li,
  .ansli_right li {
    margin-bottom: 0.5rem;
    list-style-type: disc;
  }
  


.bubbly-cloud-butt {
    font-family: Arial, sans-serif;
    font-size: 2rem; 
    padding: 1rem 3rem; 
    border: none;
    border-radius: 5px;
    background-color: lightblue;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    color: #555555;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
    cursor: pointer;
    animation: pulse 2s infinite alternate;
    transition: all 0.3s ease;
    min-width: 365px;
    min-height: 100px;

}

.bubbly-cloud-butt:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    border-radius: 50px;

}

.bubbly-cloud-butt:active {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), inset 0 1px 2px rgba(255, 255, 255, 0.3);
}

.bubbly-cloud-butt a {
    text-decoration: none; 
    color: black; 

}

.ans-container {
  background-color: white;
  color: black;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  display: block;
  max-width: 600px;
  padding-left: 1.5rem;
  overflow: hidden; 
  max-height: 0;
  transition: max-height 0.3s ease;
}

.ans-container.show {
  transition: max-height 3s ease; /* Smooth transition */
  max-height: 1000px; /* Expand to fit content */
  min-width: 700px;
}

/* the colors */

.color-one {
  color: #ff99c8;
}

.color-two {
  color: #f1c34d;
}

.color-three {
  color: #71cb7a;
}

.color-four {
  color: #2da9d6;
}

.color-five {
  color: #8530ff;
}

@media (max-width: 768px) {
  .back2 {
    background-color: rgba(234, 122, 212, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    border-radius: 50px;
    padding: 1rem;
    max-width: 400px;
  }

  .madimi-one-regular {
    font-size: 16px;
  }

  .faq-btn {
    min-width: 300px;
    max-width: 250px;
    min-height: 60px;
    border-radius: 30px;
    background-color: white;
    border: none;
    margin-left: 0;
    padding-left: 15px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .qa-form .ans {
    font-size: 1.2rem; 
    color: white; 
    margin-bottom: 1rem; 
    padding: 2.5rem; 
    max-width: 300px;
  }

  .ans-container {
    background-color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    display: block;
    max-width: 300px;
    padding-left: 1.5rem;
    overflow: hidden; 
    max-height: 0;
    transition: max-height 0.3s ease;
  }

  .ans-container.show {
    transition: max-height 3s ease; /* Smooth transition */
    max-height: 1000px; /* Expand to fit content */
    max-width: 300px;
    min-width: 300px;
  }

  .bubbly-cloud-butt {
    font-family: Arial, sans-serif;
    font-size: 2rem; 
    padding: 1rem 3rem; 
    border: none;
    border-radius: 5px;
    background-color: lightblue;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    color: #555555;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
    cursor: pointer;
    animation: pulse 2s infinite alternate;
    transition: all 0.3s ease;
    min-width: 200px;
    max-width: 200px;
    min-height: 100px;

}

.bubbly-cloud-butt:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    border-radius: 50px;

}

.bubbly-cloud-butt:active {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), inset 0 1px 2px rgba(255, 255, 255, 0.3);
}

.bubbly-cloud-butt a {
    text-decoration: none; 
    color: black; 

}
}