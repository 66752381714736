.loading-screen {
    position: fixed;
    top: calc(60px + 3.7rem); /* Adjust this value according to your navbar height and desired offset */
    left: 0;
    width: 100%;
    height: calc(100vh - 60px - 3.7rem); /* Adjust this value according to your navbar height and desired offset */
    background-color: transparent;
    z-index: 9999;
    transition: opacity 1s ease-in-out;
    opacity: 0; /* Initially hidden */
}

.loading-screen.show {
    background-color: pink;
    opacity: 1;
}

.pink-bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-image {
    width: 200px; /* Adjust image size as needed */
    height: auto;
}

.hide {
    opacity: 0;
}

.loading-text {
    color: black;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}