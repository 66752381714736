.containerez {
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  .centralized {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub-containerez {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(226, 152, 255, 0.5);
    gap: 30px;
    border-radius: 45px;
    height: auto;
    max-width: 800px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
  }

  .lefty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 65%;
    font-size: 1.5rem;
    gap: 50px;
    color: white;
  }

  .leftimage {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .righty-content {
    width: 80%;
    background-color: rgb(251, 175, 188);
    max-height: 450px;
    overflow-y: auto;

  }
  
  .lefty-content > div {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .nerko-one-welcometowatiszine {
    font-family: "Nerko One", cursive;
    font-weight: 800;
    font-style: normal;
    color: white;
    font-size: 4rem;
  }
   

.wix-madefor-display {
  font-family: "Wix Madefor Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.lost {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
}

@media (max-width: 768px) {
  .sub-containerez {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 50px;
  }

  .lefty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
    gap: 10px;
    color: white;
  }

  .lefty-content > div {
    margin-bottom: 0px;
    text-align: center;
  }

  .righty-content {
    background-color: rgb(251, 175, 188);
    max-height: 400px;
    height: fit-content;
    width: 5;


  }

  .containerez {
    padding: 2rem;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }
}
