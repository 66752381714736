.sub {
    background-color: rgba(255, 120, 142, 0.5);
    text-decoration: none;
    padding-left: 18rem;
    padding-right: 18rem;
    padding-bottom: 5rem;
    align-items: center;
    margin-bottom: 0;
    padding-top: 2rem;
    color: white;
    border-radius: 10px;
    border: 2px solid white;
}

.bkgground {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qn {
    font-size: 2rem;
    text-align: center;
    border-bottom: 2rem;
    padding-bottom: 2rem;
    
}

.sub a{
    text-decoration: none;
    font-size: 2.15rem;
    color: rgb(0, 0, 0);
    align-items: center;
    padding-left: 0rem;
}

.sub a:hover{
    font-size: 2.15rem;
    display: flex;
    align-items: center;
}

.bubbly-cloud-butt {
    font-family: Arial, sans-serif;
    font-size: 2rem; 
    padding: 1rem 3rem; 
    border: none;
    border-radius: 5px;
    background-color: lightblue;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    color: #555555;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
    cursor: pointer;
    animation: pulse 2s infinite alternate;
    transition: all 0.3s ease;
    width: 365px;
    height: 100px;

}

.bubbly-cloud-butt:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), inset 0 2px 4px rgba(255, 255, 255, 0.5);
    border-radius: 50px;

}

.bubbly-cloud-butt:active {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), inset 0 1px 2px rgba(255, 255, 255, 0.3);
}

.bubbly-cloud-butt a {
    text-decoration: none; 
    color: black; 

}

@media(max-width: 768px) {
    .sub {
        background-color: rgba(255, 120, 142, 0.5);
        text-decoration: none;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 5px;
        align-items: center;
        margin-bottom: 0;
        padding-top: 2px;
        color: white;
        border-radius: 10px;
        border: 2px solid white;
        max-width: 400px;
    }

    .bkgground {
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sub {
        max-width: 300px;
    }

    .bubbly-cloud-butt {
        padding: 0rem 0rem;
        max-width: 50px;
        max-height: 30px;
        margin: 0;
    }

    .bubbly-cloud-butt a{
        font-size: 24px;
        padding: 0;

    }
}