/* Updated CSS for admin panel */
.admin-panel {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightblue;
  }
  
  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 820px;

  }
   
  .admin-button {
    padding: 10px;
    font-size: 16px;
    width: 400px;
    height: 400px;
    border: none;
    border-radius: 10px;
    background-color: rgba(23, 7, 76, 0.6);
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }
  
  .admin-button:hover {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.5);
  }
  