.list {
  display: flex;
  justify-content: space-between;
}

.night,
.cookie,
.stt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px; /* Add margin between event sections */
}

.events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eachimgspecific {
  height: 300px;
  width: 300px;
}

.night {
  background-color: rgb(225, 255, 254);
}

.cookie {
  background-color: rgb(255, 231, 199);
}

.stt {
  background-color: rgb(255, 217, 223);
}

.bkground {
  background-color: rgb(246, 225, 202);
  padding: 2rem;
}

.anstowat {
  font-size: 2rem;
  text-align: center;
  padding: 1rem 0; /* Shorter padding syntax */
  font-style: oblique;
}

.gallery-container {
  display: flex;
  justify-content: center;
}

/* slideshow.css */
.slide-properties {
  --duration: 3000ms;
  --transition-duration: 500ms;
  --infinite: true;
  --indicators: true;
  --arrows: true;
}

.each-slide {
  display: flex;
  justify-content: center;
}

.each-slide img {
  max-width: 100%;
  max-height: calc(100vh - 100px);
  width: auto;
  height: auto;
  object-fit: contain;
}

.images-caption-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.images--row { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.images--row ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebyside {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-radius: 50px;
  background-color: rgba(190, 239, 255, 0.7);
}

.rightarrow {
  background-color: rgb(135, 3, 135, 0.5);
  border-radius: 25px;
}

.madimi-one-smol {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.madimi-one-regular {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

@media (max-width: 768px) {
  .images--row { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sidebyside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 5px;
    border-radius: 50px;
    background-color: rgba(190, 239, 255, 0.7);
  }

  .eachimgspecific {
    width: 200px;
    height: 200px;
  }

  .bkground {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
}